import { usePresenceListener } from 'ably/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import { setUrlCheckData, setPresenceData } from '../redux-toolkit/reducer/ablyReducer';

export default function useAblyPortalPresenceHook({ channel }) {
    const dispatch = useDispatch();
    const { currentPortal, urlCheckData } = useSelector(
        ({ portal, ably }) => ({
            currentPortal: portal?.portal,

            urlCheckData: ably?.urlCheckData,
        }),
        _.isEqual,
    );

    const { presenceData } = usePresenceListener('portals');

    useEffect(() => {
        if (presenceData?.length > 0) {
            // Get the latest presence entry per clientId based on timestamp
            const latestPresenceData = _.chain(presenceData)
                .groupBy('clientId')
                .map((entries) => _.maxBy(entries, 'timestamp')) // Get the latest entry per clientId
                .compact() // Remove any undefined values
                .value();
            dispatch(setPresenceData(latestPresenceData));

            const isPortalOnline = latestPresenceData?.find(
                (_portal) => _portal?.clientId === currentPortal?.portal_id,
            );

            if (!isPortalOnline && urlCheckData.length > 0) {
                const transformedData = urlCheckData.reduce((acc, curr) => {
                    const data = { ...curr, host: curr.host, alive: false, reason: 'Offline' };
                    return [...acc, data];
                }, []);

                dispatch(setUrlCheckData(transformedData));
            }
        }
    }, [presenceData, dispatch, currentPortal?.portal_id, urlCheckData]);

    return null;
}
